import React from 'react';
import AuditTableHeader from './AuditTableHeader'
import AuditTableBody from './AuditTableBody';
import axiosInstance from '../../Shared/Interceptor/interceptor';
import appConfig from '../../../Environment/environments';
import DialogBox from "../OptionsMenu/dialog";
import {
  MOVEUSERTO, USERACTIVE, GROUPUSERAPI, GROUPSWAPUSERAPI, APPUSERACTIVE, APPUSERAPI, DELETETEXT,
 APPUSERSTATUS, USERTIMEOUTERROR, USERSWAPSUCCESS, ERRORMSG, NORECORDFOUND,GET_AUDIT_ACTION_DETAILS
} from '../../../Common/Constants/constants';
import { encryptData } from '../../../Common/Utilities/utility';
import RightDrawer from '../../Shared/RightDrawer/RightDrawer';
import ChangeGroup from '../../Groups/changeGroup/changeGroup';
import ToastMessages from '../../Shared/Toast/ToastMessages';
import Users from '../../../Common/Services/Users';
import AuditDetails from './AuditDetails';


class AuditTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changedialogWidth: { width: 600 },
      filtered: [],
      id: "",
      right: false,
      groupGrid: false,
      auditDrawer:false,
      auditDetails:{},
      userId: 0,
      groupId: 0,
      statusMessage: false,
      message: '',
      toastVPosition: 'top',
      toastHPosition: 'center',
      messageVariant: '',
      swapAdminLabel: '',
      perPageList: [5, 10, 20, 50],
      perPage: 10,
      pageNo: 1,
      offset: "0px",
      fullScreen: false,
    }
    this.onClickRemoveItem = this.onClickRemoveItem.bind(this);
    this.onhandleChangeActive = this.onhandleChangeActive.bind(this);
    this.onClickMoveItem = this.onClickMoveItem.bind(this);
    this.onClickLogDetails = this.onClickLogDetails.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  componentDidMount() {
    this.setState({
      filtered: this.props.items,
      groupId: this.props.groupId,
      groupGrid: (this.props.type === 'group') ? true : false
    });
  }

  static getDerivedStateFromProps(props, state) {
    return { ...state, filtered: props.items };
  }

  toggleDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (side === "auditDrawer") {
      this.setState({
        auditDrawer: false,
        
      });
    }
  }

  handleToast(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      statusMessage: false
    });
  }

  onhandleChangeActive(entry) {
    let currentList = this.props.items;
    let newList = currentList.map((item) => {
      let result = (this.props.type === 'group') ? (entry.groupUserId === item.groupUserId) : (entry.appUserId == item.appUserId);
      if (result) {
        item.isActive = !item.isActive;
        let URL = (this.props.type === 'app') ? (APPUSERACTIVE + encryptData(item.appUserId).toString()) : (USERACTIVE + encryptData(item.groupUserId));
        axiosInstance.put(appConfig.api.development + URL + "&userStatus=" + encryptData(item.isActive), {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }).then(() => {
        });
      }
      return item;
    });

    this.setState({ filtered: newList });
    this.props.onContentChange && this.props.onContentChange();
  }

  onClickRemoveItem(entry, index) {
    this.setState({
      opennow: true,
      id: (this.props.type === 'group') ? entry.groupUserId : entry.appUserId
    });
  }

  onClickMoveItem(entry) {
    this.setState({
      right: true,
      userId: entry.userId,
      id: (this.props.type === 'group') ? entry.groupUserId : entry.appUserId
    });
    this.toggleDrawer('right', true);
  }

  onCloseEvent = (props) => {
    this.setState({ opennow: false })
  }

  onCloseParentPopUp = () => {
    this.setState({
      right: false
    });
  }

  onClickLogDetails=async (details)=>
  {
    this.setState({
      auditDrawer: true,
      auditDetails:details
    });
  }

  onCloseSaveClick = () => {
    let currentList = this.props.items;
    let mylist;
    currentList.map((item, itemIndex) => {
      let result = (this.props.type === 'group') ? (this.state.id === item.groupUserId) : (this.state.id == item.appUserId);
      if (result) {
        mylist = itemIndex
        return item;
      }
    });
    currentList.splice(mylist, 1)
    this.setState({ filtered: currentList });
    if (this.props.gridType === 0) {
      this.props.onDeleteAdmin && this.props.onDeleteAdmin(currentList.length);
    }
    this.props.onContentChange && this.props.onContentChange();
  }

  handleCloseDialogue = () => {
    let currentList = this.props.items;
    let mylist
    this.setState({ opennow: false });
    currentList.map((item, itemIndex) => {
      let result = (this.props.type === 'group') ? (this.state.id === item.groupUserId) : (this.state.id == item.appUserId);
      if (result) {
        let URL = (this.props.type === 'app') ? (APPUSERAPI + encryptData(item.appUserId).toString()) : (GROUPUSERAPI + encryptData(item.groupUserId).toString());
        axiosInstance.delete(appConfig.api.development + URL, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          }
        })
        mylist = itemIndex
        return item;
      }
    });
    currentList.splice(mylist, 1)
    this.setState({ filtered: currentList });
    if (this.props.gridType === 0) {
      this.props.onDeleteAdmin && this.props.onDeleteAdmin(currentList.length);
    }
    this.props.onContentChange && this.props.onContentChange();
  };

  getSortedData = (data, sort = true, column="name") => {
    if(column){
      if (sort) {
          data.sort((a, b) => (a[column].toLowerCase() >= b[column].toLowerCase()) ? 1 : -1)
      }
      else {
          data.sort((a, b) => (a[column].toLowerCase() <= b[column].toLowerCase()) ? 1 : -1)
      }
    }else{
      return data;
    }
  }

  sortData = (sort, column) => {
    const sortedData = this.getSortedData(this.state.filtered, sort, column)
    this.setState({ filtered: sortedData })
  }

  onClose=()=>{
      this.setState({
        auditDrawer: false
      });
      this.props.closeParentEditGroup(false)
  }

  onDrawerClose=()=>{
    this.setState({
      auditDrawer: false
    });
  }


  render() {
    return (
      <div className="App">
        <div className="table">
          <AuditTableHeader gridType={this.props.gridType} isTimeout={this.props.isTimeout} type={this.props.type} sortData={this.sortData} />
          {
            (this.state.filtered.length != 0) ? 
            <>
            {this.state.filtered.map((item, idx) =>            
              <AuditTableBody
                key={idx}
                {...item}
                onClickRemoveItem={this.onClickRemoveItem}
                onhandleChangeActive={this.onhandleChangeActive}
                onClickMoveItem={this.onClickMoveItem}
                onClickLogDetails={this.onClickLogDetails}
                type={this.props.type}
                gridType={this.props.gridType}
              />
            )}
            {/* <div className="paginationDiv">
              <Pagination
                count={this.state.filtered.length}
                size="large"
                page={this.state.pageNo}
                variant="outlined"
                shape="rounded"
                onChange={this.handlePageChange}
              />
              <div className="selectDiv">
                <span>Items per page</span>
                <span style={{marginLeft:5 + "px"}}>
                <Select
                  value={this.state.perPage}
                  onChange={this.handlePerPageChange}
                >
                  {this.state.perPageList.map((value, index) => {
                    return <MenuItem value={value}>{value}</MenuItem>;
                  })}
                </Select>
                </span>
              </div>
            </div> */}
            </>
            : ((this.state.filtered.length == 0) ?              
              <div className="noUsersInfo">
                <p className="noUsersContent">{NORECORDFOUND}</p>
              </div>
              :<></>)
          }
        </div>
        <ToastMessages statusMessage={this.state.statusMessage}
          message={this.state.message}
          variant={this.state.messageVariant}
          toastHPosition={this.state.toastHPosition}
          toastVPosition={this.state.toastVPosition}
          close={this.handleToast.bind(this)} />
        <DialogBox dialogContent={this.props.dialogContent} dialogHead={this.props.dialogHead} dialogButtonText={DELETETEXT} opennow={this.state.opennow} onCloseClick={this.handleCloseDialogue} onCloseEvent={this.onCloseEvent} />
        <RightDrawer customProps={this.state.changedialogWidth} open={this.state.right}>
          <ChangeGroup
            callApiOnSave
            headerLabel={MOVEUSERTO}
            saveType="moveUser"
            gridType={this.props.gridType}
            userId={this.state.userId}
            GroupId={this.state.groupId}
            onSave={this.onCloseSaveClick}
            onClose={this.onCloseParentPopUp} 
            isViewOnly={this.props.items.filter(x=>x.userId==this.state.userId)[0]?.isViewOnly}/>
        </RightDrawer>
        <RightDrawer customProps={{ right: this.state.offset }} open={this.state.auditDrawer}>
          <AuditDetails
          Id={this.state.id}
          expandedAuditDetails={this.state.auditDetails}
           onClose={this.onClose}
           onDrawerClose={this.onDrawerClose}
          ></AuditDetails>
        </RightDrawer>
      </div>
    );
  }
}

export default AuditTable;