const version = '1.4.0.0';
const postLogOutUri ='https://dev.carrierappshub1.com';
const apiUri = 'https://devapi.carrierappshub1.com';
const authModuleVer = '2.1.9';
const storageBaseUri = 'https://d1oqom3ssbr3mh.cloudfront.net/';

export const appConfig = {
  api: {
    development: apiUri,
    postLogoutRedirectUri: postLogOutUri,
    encryption: {
      IV: "8080808080808080",
      Key: "8080808080808080"
    },
    version: version,
    authVersion: authModuleVer,
    authSettings: {
      azureIss: "https://unob2c.b2clogin.com/4d0da70f-3b49-4630-86c9-3e3aab84ecae/v2.0/",
      awsIss: "https://cognito-idp.us-east-2.amazonaws.com/us-east-2_s5NUFfIbw"
    },
    blobUrl: storageBaseUri + 'logo/',
    emailUrl:storageBaseUri+'email/',
    storageUrl: storageBaseUri,
    loginConfig: {
      apiUrl: apiUri + '/',
      uiUrl: postLogOutUri,
      postLogOutUri: postLogOutUri,
      redirectAppUri:"https://authdev.carrierappshub1.com",
      appClientId: '8e730aca-13b1-4b97-baf5-2322e234787d'
    }
  }
}

export default appConfig
