import React from 'react';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Menu, MenuItem, Chip } from '@material-ui/core';
import { ReactComponent as InternalUser } from '../../../Assests/Images/Logo.svg';
import { ReactComponent as HVACUser } from '../../../Assests/Images/HvacPartner.svg';
import { ReactComponent as ExternalUser } from '../../../Assests/Images/User.svg';
import { ReactComponent as Delete } from '../../../Assests/Images/Delete.svg';
import {
  TableBodyStyles, INVITATIONPENDING, DELETETOOLTIP, DELETEUSER, MOVEUSER, SETADMIN, SETUSER, ON, OFF, MAXREACHED,
  INTERNALUSER, EXTERNALUSER, HVACUSER, ACCOUNTLOCKMESSAGE, DEACTIVATEDMESSAGE, ACCOUNTDEACTIVATION, DAYS, EXPIRYON, GROUPTIMEOUTDEACTIVATEDMESSAGE, RESENDINVITE,VIEWONLYADMIN,SWAPVIEWONLYADMIN
} from '../../../Common/Constants/constants';
import { intlShape, injectIntl } from "react-intl";
import { injectIntlTranslation } from "../../../Common/Utilities/utility";

const CustomColorCheckbox = withStyles({
  root: {
    color: "#A6A6A6",
    "&$checked": {
      color: "#EB1616"
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);

const IOSSwitch = withStyles(theme => TableBodyStyles)(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function TableBody(props) {
  const intl = props.intl;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    props.onClickStatusItem(props);
  }

  const showDeactiveUsers = (props) => {
    return (
      <div>
        {(props.status === 1) ? <span className="userMsg">{DEACTIVATEDMESSAGE} </span> : ""}
        {(props.status === 2) ? <Chip
            label={(
              <div>{ACCOUNTDEACTIVATION} <b>{props.deactivatedDaysCount} {DAYS}</b></div>
            )}
            size="small"
            onDelete={handleDelete}
            className="chipBgColor"
            deleteIcon={<RefreshIcon className="refreshBgColor" />}
          /> : ""}
        {(props.status === 3) ? <span className="userMsg">{GROUPTIMEOUTDEACTIVATEDMESSAGE} </span> : ""}
        {(props.status === 4) ? <span className="userMsg">{ACCOUNTLOCKMESSAGE} </span> : ""}

      </div>
    )
  }

  return (
    <div className={`table-body ${props.status === 3 ? " table-body-gray" : " table-body-white"}`} >
      <div className="table-td table-body-white userIcon">
        <center>
          {<ExternalUser />}
        </center>
      </div>
      <div className='table-td'>
        <div className="userInfo">
          <span className="userName">{props.name}</span> 
          <div className="userEmail">{props.email} {props.isViewOnly?<span className="viewOnlyAdmin">{VIEWONLYADMIN}</span>:""} </div>
          <div className={"xs-show userLastSigned "}>
            {props.lastSigned == null || props.lastSigned == "" ?
              <span className="invitationPending">({INVITATIONPENDING})</span> : props.lastSigned}
            {(props.gridType === 1) ? showDeactiveUsers(props) : ""}
          </div>
        </div>
      </div>
      <div className="table-td xs-hide userLastSigned">
        <div className="userInfo">
          {props.lastSigned == null || props.lastSigned == "" ? <span className="invitationPending">({INVITATIONPENDING})</span> : <div className="datetime">{props.lastSigned}</div>}
          {(props.gridType === 1) ? showDeactiveUsers(props) : ""}
        </div>
      </div>
      <div className="table-td">
        <FormControlLabel
          control={
            <IOSSwitch
              checked={props.isActive}
              onChange={() => props.onhandleChangeActive(props)}
              value={props.isActive}
              disabled={(props.buttonDisabled) ? true : false}
            />
          }
          label={props.isActive ? ON : OFF}
        />
      </div>
      {
        (props.gridType === 1 && props.isTimeout === true) ?
          <div className="table-td xs-hide userTimeout">
            <FormControlLabel
              control={
                <CustomColorCheckbox checked={props.isTimeoutOff === false}
                  onChange={() => props.onClickTimeout(props)} />
              }
            />
            {(props.isTimeoutOff === false) ?
              <span>{EXPIRYON} {props.nextTimeout}</span> : ""
            }
          </div>
          : ""
      }
      <div className="table-td table-body-white">
        {props.type === 'group' ?
          <a href="#" onClick={handleClick}><MoreVertIcon /> </a> :
          <a href="#" className="removeitem" title={injectIntlTranslation(intl, DELETETOOLTIP)} onClick={() => props.onClickRemoveItem(props)}><Delete /> </a>}
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {(props.lastSigned == null || props.lastSigned == "") ?
        <MenuItem onClick={handleClose} disabled={(props.buttonDisabled) ? 'disabled' : ''}><a href="#" onClick={() => props.onResendInvitationEmail(props)}>{injectIntlTranslation(intl, RESENDINVITE)}</a></MenuItem> : ""}  
        <MenuItem onClick={handleClose} disabled={(props.buttonDisabled) ? 'disabled' : ''}><a href="#" onClick={() => props.onClickMoveItem(props)}>{injectIntlTranslation(intl, MOVEUSER)}</a></MenuItem>
        <MenuItem onClick={handleClose}
          disabled={(((props.adminCount >= props.maxAdminCount) && (props.gridType === 1)) || (props.buttonDisabled)) ? 'disabled' : ''}>
          <a href="#" onClick={() => props.onClickSwapUser(props)}>
            {(props.gridType === 0 && props.isViewOnly) ?injectIntlTranslation(intl, SETADMIN)  : (props.gridType === 0 && !props.isViewOnly)? injectIntlTranslation(intl, SETUSER):injectIntlTranslation(intl, SETADMIN)}
            {(props.gridType === 1) ? (props.adminCount >= props.maxAdminCount) ? injectIntlTranslation(intl, MAXREACHED) : ` (${props.adminCount}/${props.maxAdminCount})` : ''}
          </a>
        </MenuItem>
       {!props.isViewOnly? 
       <MenuItem onClick={handleClose} disabled={(props.buttonDisabled) ? 'disabled' : ''}>
       <a href="#" onClick={() => props.onClickSwapUser(props,true)}>{injectIntlTranslation(intl, SWAPVIEWONLYADMIN)}</a>
       </MenuItem>:""}
       {props.isViewOnly? 
       <MenuItem onClick={handleClose} disabled={(props.buttonDisabled) ? 'disabled' : ''}>
       <a href="#" onClick={() => props.onClickSwapUser(props,false,true)}>{injectIntlTranslation(intl, SETUSER)}</a>
       </MenuItem>:""}
        <MenuItem onClick={handleClose} disabled={(props.buttonDisabled) ? 'disabled' : ''}><a href="#" onClick={() => props.onClickRemoveItem(props)}>{injectIntlTranslation(intl, DELETEUSER)}</a></MenuItem>
      </Menu>
    </div >
  );
}


TableBody.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(TableBody);